import React from 'react';
import { NavLink } from 'react-router-dom';
import { withCardApi } from '../../contexts/api';
import { withFirebase } from '../../contexts/firebase';
import styles from './preview.module.scss';
import { getActiveUserCount } from '../../utils';
import { selectors as userSelectors } from '../../contexts/redux/user';
import { selectors as lobbySelectors } from '../../contexts/redux/lobby';
import { selectors as gameSelectors } from '../../contexts/redux/game';
import { connect } from 'react-redux';

type PreviewTypes = {
  activeLobby?: Lobby;
  activeGame?: Game;
};

const Preview = ({ activeLobby, activeGame }: PreviewTypes) => {
  if (!activeGame && !activeLobby) {
    return null;
  }
  if (activeLobby) {
    return (
      <div className={styles.preview}>
        <div>
          <span>{activeLobby.name}</span>
        </div>
        <div>
          {getActiveUserCount(activeLobby)} / {activeLobby.maxPlayerCount}
        </div>
      </div>
    );
  }
  return (
    <div className={styles.preview}>
      <NavLink to={`/game/${activeGame?.gameId}`}>
        <div>
          <span>Go to game</span>
        </div>
      </NavLink>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  const user = userSelectors.getUser(state);
  if (!user?.active?.id) {
    return {};
  }
  if (user.active.type === 'lobby') {
    return {
      activeLobby: lobbySelectors.getCurrentLobby(state),
    };
  }
  return {
    activeGame: gameSelectors.getGame(state),
  };
};

export default connect(mapStateToProps)(withFirebase(withCardApi(Preview)));
