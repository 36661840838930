import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import lobbiesReducer from './lobby';
import userReducer from './user';
import gameReducer from './game';

const reducer = combineReducers({
  lobbies: lobbiesReducer,
  user: userReducer,
  game: gameReducer,
});

const store = configureStore({
  reducer,
});

export default store;
export const dispatch = store.dispatch;
