import React, { ReactNode } from 'react';

export default ({ children }: { children: ReactNode }) => (
  <table>
    <thead>
      <tr>
        <th>Name</th>
        <th>Created date</th>
        <th>Players</th>
        <th>Game Type</th>
        <th></th>
      </tr>
    </thead>
    <tbody>{children}</tbody>
  </table>
);
