import React from 'react';
import { withCardApi } from '../../contexts/api';
import { CardApiProps, FirebaseProps } from '../../types/cards';
import NavbarItem from '../../components/navbarItem';
import { withFirebase } from '../../contexts/firebase';
import styles from './navbar.module.scss';

/*
  Left navbar containing all pages - can be expanded or collapsed.
  Expanded shows text and icon and sits next to the page
  Collapsed shows icon and then expands on top of page when hovered
*/
const navbar = (props: CardApiProps & FirebaseProps) => (
  <>
    <div className={styles.navUpper}>
      {/* This one should sit at the top of the page in the corner in a different style*/}
      <NavbarItem location="/lobby/create" title="Create new game" className={styles.navbarItem} />

      <NavbarItem location="/" title="Home" className={styles.navbarItem} />
      <NavbarItem location="/club" title="My Club" className={styles.navbarItem} />
      <NavbarItem location="/lobby" title="Lobby" className={styles.navbarItem} />

      {/* This one sits at the bottom left and is a popup menu for account controls */}
    </div>
    <div className={styles.navLower}>
      <NavbarItem location="/account" title="Account" />
      <button onClick={props.firebase.signOut}>Log out</button>
    </div>
  </>
);

export default withCardApi(withFirebase(navbar));
