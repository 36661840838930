import ResourceLoader from '../resourceLoader';
import { updateGame, updateCurrentGameState } from '../../../redux/game';
import { dispatch } from '../../../redux/index';
import { Games } from '@card-table/games';
export default class GameApi extends ResourceLoader {
  updateGame = (game: Game) => {
    dispatch(updateGame(game));
  };

  updateCurrentGameState = (gameState: Games.GameState) => {
    dispatch(updateCurrentGameState(gameState));
  };

  createGame = () => {
    // create a new game from a lobby
  };

  getGames = () => {
    // get all games this user can see
  };

  getGame = () => {
    // get all info about a current game
  };

  playMove = async (gameId: string, event: Games.UserGameEvent) => {
    const doMove = this.firebase.functions.httpsCallable('doMove');
    const result = await doMove({ gameId, event });
    console.log(result.data);
  };

  startGame = async (lobbyId: string, settings: Games.GameInitSettings) => {
    const start = this.firebase.functions.httpsCallable('startGame');
    debugger;
    console.log(start);
    const response = await start({
      lobbyId,
      settings,
    });
    debugger;
    console.log(response);
  };
}
