import React, { Component } from 'react';
import { withFirebase, Unsubscribe } from './contexts/firebase';
import PreAuth from './preauth';
import PostAuth from './postauth';
import { CardApiProps, FirebaseProps } from './types/cards';
import { withCardApi } from './contexts/api';
import styles from './app.module.scss';

class App extends Component<FirebaseProps & CardApiProps> {
  state = {
    authUser: null,
    pendingAuth: false,
  };

  listener: Unsubscribe | undefined;

  componentDidMount() {
    const { firebase, api } = this.props;
    this.listener = firebase.onAuthStateChanged((authUser) => {
      if (authUser) {
        // force the system to connect to the backend at page load
        // to double check the user exists still
        this.setState(
          {
            pendingAuth: true,
          },
          () => {
            authUser
              .getIdToken(true)
              .then(() => {
                api.connect(authUser.uid);
                this.setState({
                  authUser,
                  pendingAuth: false,
                });
              })
              .catch((e) => {
                this.setState({
                  authUser: null,
                  pendingAuth: false,
                });
              });
          },
        );
      } else {
        this.setState({
          authUser: null,
          pendingAuth: false,
        });
      }
    });
  }

  componentWillUnmount() {
    this.listener?.();
  }

  render() {
    const { authUser, pendingAuth } = this.state;
    if (pendingAuth) {
      return (
        <div className={styles.loadingWrapper}>
          <div className={styles.loader}>
            <span>Loading...</span>
          </div>
        </div>
      );
    }
    return (
      <>
        {!authUser && <PreAuth />}
        {authUser && <PostAuth />}
      </>
    );
  }
}

export default withCardApi(withFirebase(App));
