import LobbyApi from './resources/lobby';
import GameApi from './resources/game';
import UserApi from './resources/user';
import ClubApi from './resources/club';
import axios from 'axios';
import Firebase from '../firebase';
import { Loader } from './resources/loader';
import { Games } from '@card-table/games';

declare global {
  interface Window {
    api: CardApi;
    seed: () => void;
    l1: () => void;
    l2: () => void;
    l3: () => void;
    l4: () => void;
  }
}

const baseApiUrl = 'http://localhost:5001/card-table-co-uk/us-central1';
const apiVersion = 'v1';

type ActiveDisconnect = UserActive & {
  disconnect?: () => void;
};

class CardApi {
  game: GameApi;
  lobby: LobbyApi;
  user: UserApi;
  club: ClubApi;

  private firebase: Firebase;

  userWatcherDisconnect: undefined | (() => void);
  handDisconnect: undefined | (() => void);
  active?: ActiveDisconnect;

  constructor(firebase: Firebase) {
    window.api = this;
    this.firebase = firebase;

    this.game = new GameApi(this.loader, this.firebase);
    this.lobby = new LobbyApi(this.loader, this.firebase);
    this.user = new UserApi(this.loader, this.firebase);
    this.club = new ClubApi(this.loader, this.firebase);

    window.seed = async () => {
      try {
        await this.firebase.createUser('test1@branks.co.uk', 'Password1');
      } catch (e) {}
      try {
        await this.firebase.createUser('test2@branks.co.uk', 'Password1');
      } catch (e) {}
      try {
        await this.firebase.createUser('test3@branks.co.uk', 'Password1');
      } catch (e) {}
      try {
        await this.firebase.createUser('test4@branks.co.uk', 'Password1');
      } catch (e) {}
      // await this.lobby.createLobby(userA.user?.uid, 'BRIDGE');
      // await this.firebase.signOut();

      // await this.firebase.signIn('test2@branks.co.uk', 'Password1');
      // await this.lobby.createLobby(userB.user?.uid, 'BRIDGE');
      // await await this.firebase.signOut();
      // await this.firebase.signIn('test3@branks.co.uk', 'Password1');
    };

    window.l1 = async () => {
      await this.firebase.signIn('test1@branks.co.uk', 'Password1');
    };
    window.l2 = async () => {
      await this.firebase.signIn('test2@branks.co.uk', 'Password1');
    };
    window.l3 = async () => {
      await this.firebase.signIn('test3@branks.co.uk', 'Password1');
    };
    window.l4 = async () => {
      await this.firebase.signIn('test4@branks.co.uk', 'Password1');
    };
  }

  private loader: Loader = {
    get: async (url: string, params: any) => {
      const token = await this.firebase.getCurrentToken();
      const response = await axios.get(`${baseApiUrl}/api/${apiVersion}/${url}`, { headers: { Authorization: `Bearer ${token}` }, params });
      if (response.status < 200 || response.status > 299) {
        throw new Error();
      }
      return response;
    },
    post: async (url: string, body: any) => {
      const token = await this.firebase.getCurrentToken();
      const response = await axios.post(`${baseApiUrl}/api/${apiVersion}/${url}`, body, { headers: { Authorization: `Bearer ${token}` } });
      if (response.status < 200 || response.status > 299) {
        throw new Error();
      }
      return response;
    },
  };

  connect = (uid: string) => {
    this.user.connect(uid);
    const userWatcher = this.firebase.firestore.collection('user').doc(this.firebase.currentUser?.uid);
    this.userWatcherDisconnect = userWatcher.onSnapshot((doc) => {
      const user = doc.data() as User;
      if (!user) {
        return;
      }
      this.user.updateUser(user);

      // User is no longer in an active "thing" so disconnect
      if (this.active?.id && !user.active?.id) {
        this.active?.disconnect?.();
        return;
      }

      if (user.active?.id && (this.active?.id !== user.active.id || this.active?.type !== user.active.type)) {
        // there is a new lobby we're connected to, so dc and reconnect
        this.active?.disconnect?.();
        const activeWatcher = this.firebase.firestore.collection(user.active?.type).doc(user.active?.id);

        this.active = {
          id: user.active.id,
          type: user.active.type,
        };

        this.active.disconnect = activeWatcher.onSnapshot((doc) => {
          const active = doc.data() as Lobby | Game;
          console.log((active as Game).gameId);
          if (this.active?.type === 'game') {
            const activeGame = active as Game;
            this.game.updateGame(activeGame);

            const activeHand = this.firebase.firestore.collection('game').doc(activeGame.gameId).collection('hands').doc(user.userId);
            this.handDisconnect = activeHand.onSnapshot((hand) => {
              this.game.updateCurrentGameState(hand.data() as Games.GameInitSettings);
              console.log(hand.data());
            });
          } else {
            const activeLobby = active as Lobby;
            this.lobby.setActiveLobby(activeLobby);
          }
        });
      }
    });
  };

  disconnect = (uid: string) => {
    this.userWatcherDisconnect?.();
    this.user.disconnect(uid);
    this.handDisconnect?.();
  };
}

export default CardApi;
