import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';
import 'firebase/functions';
import firebase from 'firebase';
import CardApi from '../api';

type Unsubscribe = firebase.Unsubscribe;

declare global {
  interface Window {
    auth: Firebase;
  }
}

const firebaseConfig = {
  apiKey: 'AIzaSyBsNVOYcKmCZ6_bmtUglH10-3At59QzbcI',
  authDomain: 'card-table-co-uk.firebaseapp.com',
  databaseURL: 'https://card-table-co-uk.firebaseio.com',
  projectId: 'card-table-co-uk',
  storageBucket: 'card-table-co-uk.appspot.com',
  messagingSenderId: '680293278126',
  appId: '1:680293278126:web:2f5469923b5455a165e83f',
};

const localServer = process.env.NODE_ENV === 'development';

class Firebase {
  private auth;
  database;
  firestore;
  functions;
  currentUser: firebase.User | undefined;
  databaseHelper: typeof app.database;
  api: CardApi | undefined;

  constructor() {
    app.initializeApp(firebaseConfig);
    this.auth = app.auth();
    this.database = app.database();
    this.databaseHelper = app.database;
    this.firestore = app.firestore();
    this.functions = app.functions();

    if (localServer) {
      this.auth.useEmulator('http://localhost:9099/');
      this.database?.useEmulator('localhost', 9000);
      this.firestore?.useEmulator('localhost', 8080);
      this.functions.useEmulator('localhost', 5001);
    }
    window.auth = this;
  }

  onAuthStateChanged = (callback: (a: firebase.User | null) => any): app.Unsubscribe =>
    this.auth.onAuthStateChanged((a: firebase.User | null) => {
      if (a) {
        this.currentUser = a;
      }
      return callback(a);
    });

  getCurrentToken = () =>
    new Promise<String>((acc, rej) => {
      this?.auth?.currentUser
        ?.getIdToken(false)
        .then((idToken) => acc(idToken))
        .catch((error) => rej(error));
    });

  createUser = (email: string, password: string) => this.auth.createUserWithEmailAndPassword(email, password);

  signIn = (email: string, password: string) => this.auth.signInWithEmailAndPassword(email, password);

  signOut = () => {
    if (!this.currentUser?.uid) {
      return;
    }
    this.api?.disconnect(this.currentUser?.uid);
    this.auth.signOut();
  };

  resetPassword = (email: string) => this.auth.sendPasswordResetEmail(email);

  passwordUpdate = (password: string) => {
    if (!this.auth.currentUser) {
      throw new Error('User not logged in');
    }
    return this.auth.currentUser.updatePassword(password);
  };

  setApi(api: CardApi) {
    this.api = api;
  }
}

export default Firebase;

export type { Unsubscribe };
