import React from 'react';
import { NavLink } from 'react-router-dom';

type Props = {
  location: string;
  title: string;
  className?: string;
};

export default ({ location, title, className }: Props) => (
  <div className={className}>
    <NavLink to={location}>{title}</NavLink>
  </div>
);
