import {
  createSelector,
  createSlice,
  PayloadAction,
  Selector,
  // createEntityAdapter,
} from '@reduxjs/toolkit';

export type LobbyMap = { [key: string]: Lobby };

type State = {
  byId: StringMap<Lobby>;
  order: string[];
  loading: boolean;
  error: string;
  sort: Sort;
  cursor: string;
  active?: string;
};

// const adapter = createEntityAdapter<Lobby>({
//   selectId: (lobby) => lobby.lobbyId,
// });

const initialState: State = {
  byId: {},
  order: [],
  sort: { key: 'createdDate', order: 'desc' },
  loading: false,
  error: '',
  cursor: '',
  active: undefined,
};

const selfSelector: Selector<any, State> = (state: any): State => state?.[lobbiesSlice.name] as State;

export const selectors = {
  getLoadingState: createSelector<any, State, boolean>(selfSelector, (state) => state.loading),
  getError: createSelector<any, State, string>(selfSelector, (state) => state.error),
  getLobbies: createSelector<any, State, LobbyMap>(selfSelector, (state) => state.byId),
  getSort: createSelector<any, State, Sort>(selfSelector, (state) => state.sort),
  getLobbyOrder: createSelector<any, State, string[]>(selfSelector, (state) => state.order),
  getCursor: createSelector<any, State, string>(selfSelector, (state) => state.cursor),
  getCurrentLobby: createSelector<any, State, Lobby | undefined>(
    selfSelector,
    (state) => (state.active && state.byId[state.active]) || undefined,
  ),
};

const lobbiesSlice = createSlice({
  name: 'lobbies',
  // initialState: adapter.getInitialState(),
  initialState,
  reducers: {
    setActiveLobby: (state, action: PayloadAction<Lobby>) => {
      state.active = action.payload.lobbyId;
      state.byId[action.payload.lobbyId] = action.payload;
    },
    loadingLobbies: (state) => {
      state.loading = true;
    },
    loadedLobbies: (state) => {
      state.loading = false;
    },
    setLobbies: (state, action: PayloadAction<Lobby[]>) => {
      const { payload } = action;
      payload.forEach((lobby) => {
        const { lobbyId } = lobby;
        state.byId[lobbyId] = lobby;
        if (!state.order.includes(lobbyId)) {
          state.order.push(lobbyId);
        }
      });
      state.cursor = '';
    },
    loadLobby: (state, action: PayloadAction<Lobby>) => {
      state.byId[action.payload.lobbyId] = action.payload;
    },
    clearLobbies: (state) => {
      state.byId = {};
      state.order = [];
    },
    createLobby: (state, action: PayloadAction<Lobby>) => {
      const { payload } = action;
      const { lobbyId } = payload;
      state.byId[lobbyId] = payload;
      if (!state.order.includes(lobbyId)) {
        state.order.push(lobbyId);
      }
    },
    setError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
  },
});

export const {
  setLobbies,
  clearLobbies,
  loadingLobbies,
  loadedLobbies,
  setError,
  createLobby,
  loadLobby,
  setActiveLobby,
} = lobbiesSlice.actions;

export default lobbiesSlice.reducer;
