import React from 'react';
import { withCardApi } from '../../contexts/api';
import { CardApiProps } from '../../types/cards';
import NavbarItem from '../../components/navbarItem';
import styles from './preauthNavbar.module.scss';

const navbar = (props: CardApiProps) => (
  <div className={styles.navbar}>
    {/* <NavbarItem location="/" title="Home" /> */}
    {/* <NavbarItem location="/register" title="Register" />
    <NavbarItem location="/forgotPassword" title="ForgotPassword" /> */}
    {window.location.pathname !== '/login' && (
      <NavbarItem location="/login" title="Login" className={`${styles.navbarItem} ${styles.logIn}`} />
    )}
  </div>
);

export default withCardApi(navbar);
