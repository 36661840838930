import React, { MouseEventHandler, ReactElement } from 'react';

type Props = {
  icon: ReactElement;
  onClick: MouseEventHandler<HTMLButtonElement>;
  label: string;
};

const itemButton = ({ icon, onClick, label }: Props) => (
  <button onClick={onClick}>
    {icon && <icon.type />}
    <span>{label}</span>
  </button>
);

export default itemButton;
