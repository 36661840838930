import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Firebase, { FirebaseContext } from './contexts/firebase';
import { BrowserRouter as Router } from 'react-router-dom';
import CardApi, { CardApiContext } from './contexts/api';
import { Provider } from 'react-redux';
import store from './contexts/redux';

const firebase = new Firebase();
const api = new CardApi(firebase);
firebase.setApi(api);

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <FirebaseContext.Provider value={firebase}>
        <CardApiContext.Provider value={api}>
          <Provider store={store}>
            <App />
          </Provider>
        </CardApiContext.Provider>
      </FirebaseContext.Provider>
    </Router>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// api.createLobby()

// https://redux-toolkit.js.org/

// /lobby/
//     component - react
//     connect - redux connection
//     actions - redux connection
//     reducers - redux connection
