import ResourceLoader from '../resourceLoader';
import { dispatch } from '../../../redux/index';
import { setLobbies, loadLobby, loadingLobbies, loadedLobbies, setError, clearLobbies, setActiveLobby } from '../../../redux/lobby';
import { Games } from '@card-table/games';

const COLLECTION = 'lobby';
export default class LobbyApi extends ResourceLoader {
  clearLobbies = () => {
    dispatch(clearLobbies());
  };

  createLobby = async (userId: string | undefined, name: string, gameType?: Games.GameType) => {
    if (!userId) {
      dispatch(setError('User is not logged in'));
      return;
    }
    dispatch(loadingLobbies());
    const firestore = this.firebase.firestore;
    try {
      const userRef = firestore.collection('user').doc(userId);
      await firestore.runTransaction(
        async (transaction): Promise<Lobby> => {
          const currentUser = await transaction.get(userRef);
          if (!currentUser.exists) {
            throw new Error('User not found');
          }

          const user = currentUser.data() as User;
          if (user.active?.id) {
            throw new Error(`User already in a ${user.active.type} `);
          }

          const currentLobby = await firestore
            .collection('lobby')
            .limit(1)
            .where('host', '==', userId)
            .where('finished', '==', false)
            .get();

          if (currentLobby?.size) {
            throw new Error('User already a host of a lobby');
          }

          const newLobbyRef = firestore.collection('lobby').doc();
          const { id: lobbyId } = newLobbyRef;

          const newLobby = {
            lobbyId,
            host: userId,
            name,
            createdDate: Date.now(),
            full: false,
            gameMode: 'single',
            gameType,
            maxPlayerCount: 4,
            private: false,
          } as Lobby;

          transaction.set(newLobbyRef, newLobby);

          transaction.update(userRef, {
            active: {
              type: 'lobby',
              id: lobbyId,
            },
          } as { active: UserActive });

          return newLobby;
        },
      );
    } catch (e) {
      const error = e as Error;
      debugger;
      dispatch(setError(error.message));
    }
    //
    //   dispatch(createLobby(newLobby));
    // } catch (e) {
    //   dispatch(setError('Could not create lobby'));
    // }
    // dispatch(loadedLobbies());
  };

  getCurrentLobby = async (userId: string) => {
    // try {
    //   const lobbyRef = this.firebase.firestore.collection('currentLobby').doc(userId);
    //   const currentLobbySnapshot = await lobbyRef.get();
    //   if (!currentLobbySnapshot?.exists) {
    //     return;
    //   }
    //   const currentLobby = currentLobbySnapshot.data() as Lobby;
    //   // this.connectToLobby(userId, currentLobby.id);
    // } catch (e) {
    //   debugger;
    //   console.log(e);
    // }
  };

  getLobby = async (lobbyId: string) => {
    if (!lobbyId) {
      dispatch(setError('Cannot load selected lobby'));
      return;
    }
    dispatch(loadingLobbies());
    const load = await this.firebase.firestore.collection(COLLECTION).doc(lobbyId);
    const lobby = (await load.get()).data() as Lobby;

    dispatch(loadLobby(lobby));
    dispatch(loadedLobbies());

    // get lobby
    // const lobby: Lobby = {} as Lobby;
    // dispatch(setLobby(lobby));
  };

  /*
    get all lobbies this user can see
    params:
      - cursor: string representing where the db query should resume from - used for pagination
      - sort: string used to represent the sort column
      - search: text to search for
  */
  getLobbies = async (sort?: Sort) => {
    dispatch(loadingLobbies());
    try {
      const queryResult = await this.firebase.firestore
        .collection(COLLECTION)
        .limit(50)
        // need to add the past query to do pagination
        // can add a .startsAfter(previousQuery)
        .orderBy(sort?.key || 'name', sort?.order || 'asc')
        .get();

      const lobbies = [] as Lobby[];

      queryResult.forEach((lobby) => {
        const newLobby = lobby.data() as Lobby;
        lobbies.push(newLobby);
      });
      dispatch(setLobbies(lobbies));
    } catch (e) {
      dispatch(setError('Could not load lobbies'));
    }
    dispatch(loadedLobbies());
  };

  join = async (lobbyId: string, userId: string | undefined) => {
    if (!lobbyId || !userId) {
      dispatch(setError('Could not join selected lobby'));
      return;
    }
    dispatch(loadingLobbies());
    const joinLobby = this.firebase.functions.httpsCallable('joinLobby');
    await joinLobby({
      lobbyId,
    });
    // await this.loader.post(`lobby/${lobbyId}/join`, {});
    // const currentLobby = await this.firebase.firestore.collection('currentLobby').doc(userId).get();
    // if (!currentLobby.exists) {
    // } else {
    //   await this.firebase.firestore.collection('currentLobby').doc(userId).get();
    // }
    dispatch(loadedLobbies());
  };

  setActiveLobby = (lobby: Lobby) => {
    dispatch(setActiveLobby(lobby));
  };
}
