import React from 'react';
import Card from '../../../../components/card';
import styles from './bridgeBoard.module.scss';
import cx from 'classnames';
import BiddingCard from './components/biddingCard';
import { withCardApi } from '../../../../contexts/api';
import { CardApiProps } from '../../../../types/cards';
import { Bridge, CardGames } from '@card-table/games';

type BridgeBoardProps = {
  gameId: string;
  gameState: Bridge.GameState;
  userId: string;
};

const sortFunc = CardGames.sort('SUIT_RANK');

const BridgeBoard = ({ gameState, userId, api, gameId }: BridgeBoardProps & CardApiProps) => {
  const myPosition = gameState.positionsByPlayer[userId];
  if (!myPosition) {
    return null;
  }

  const myTurn = gameState.currentPlayer === myPosition;
  const p = Object.values(Bridge.PossiblePosition);
  const classes = [styles.bottom, styles.left, styles.top, styles.right];
  const order: Bridge.PossiblePosition[] = [myPosition, ...p.slice(p.indexOf(myPosition) + 1), ...p.slice(0, p.indexOf(myPosition))];
  return (
    <div className={styles.wrapper}>
      {order.map((pos, index) => (
        <div key={`pos_${pos}`} className={cx(styles.hand, classes[index])}>
          <span className={styles.handPos}>{pos}</span>
          {gameState.dealer === pos && <span>Dealer</span>}
          <div className={styles.cardWrapper}>
            {index === 0 &&
              gameState.hand.cards
                .sort(sortFunc)
                .map((card) => (
                  <Card
                    key={`card_${card.rank}_${card.suit}`}
                    card={card}
                    disabled={!card.playable}
                    onClick={
                      gameState.phase === 'CARDS' && gameState.dummy !== myPosition
                        ? () => api.game.playMove(gameId, { type: 'PLAY_CARD', payload: { card } })
                        : undefined
                    }
                  />
                ))}
            {index > 0 &&
              gameState.otherHands[pos].cards.map((card) => (
                <Card
                  key={`card_${card.rank}_${card.suit}`}
                  card={card}
                  disabled={!card.playable}
                  onClick={
                    gameState.phase === 'CARDS' && pos === gameState.dummy && myPosition === gameState.declarer
                      ? () => api.game.playMove(gameId, { type: 'PLAY_CARD', payload: { card } })
                      : undefined
                  }
                />
              ))}
          </div>
        </div>
      ))}
      {gameState.phase === 'CARDS' && (
        <div className={styles.scoreboard}>
          <pre>{JSON.stringify(gameState.score, null, 2)}</pre>
        </div>
      )}
      <div className={styles.centerBoard}>
        {gameState.phase === 'BIDDING' && (
          <div className={styles.bidcardWrapper}>
            <BiddingCard
              isCurrentPlayer={myTurn}
              currentBid={gameState.currentBid}
              setBid={(bid) => api.game.playMove(gameId, { type: 'BID', payload: { bid } })}
              passBid={() => api.game.playMove(gameId, { type: 'BID_PASS' })}
              doubleBid={() => api.game.playMove(gameId, { type: 'BID_DOUBLE' })}
              redoubleBid={() => api.game.playMove(gameId, { type: 'BID_REDOUBLE' })}
            />
          </div>
        )}
        {gameState.phase === 'CARDS' && (
          <div className={styles.gameWrapper}>
            <div className={styles.contract}>
              <div>
                <span>Contract</span>
              </div>
              <div>
                <pre>{JSON.stringify(gameState.contract, null, 2)}</pre>
              </div>
            </div>
            <div className={styles.cardBoardWrapper}>
              <div className={styles.cardsTitleWrapper}>
                {!!Object.keys(gameState.activeHand?.cards || {}).length && <span>Current hand</span>}
                {(!gameState.activeHand || !Object.keys(gameState.activeHand?.cards || {}).length) && (
                  <span>Winner: {gameState.pastHands?.[gameState.pastHands?.length - 1].winner}</span>
                )}
              </div>
              <div className={styles.cardBoard}>
                {!!Object.keys(gameState.activeHand?.cards || {}).length &&
                  Object.keys(gameState.activeHand?.cards || {}).map((pos) => {
                    const card = gameState?.activeHand?.cards?.[pos as Bridge.PossiblePosition];
                    if (!card) {
                      return null;
                    }
                    return (
                      <div key={`activeHand_${pos}`}>
                        <span>{pos}</span>
                        <Card card={card} />
                      </div>
                    );
                  })}
                {(!gameState.activeHand || !Object.keys(gameState.activeHand?.cards || {}).length) &&
                  Object.keys(gameState.pastHands?.[gameState.pastHands?.length - 1]?.cards || {}).map((pos) => {
                    const card = gameState.pastHands?.[gameState.pastHands?.length - 1]?.cards?.[pos as Bridge.PossiblePosition];
                    if (!card) {
                      return null;
                    }
                    return (
                      <div key={`previousHand_${pos}`}>
                        <span>{pos}</span>
                        <Card card={card} />
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default withCardApi(BridgeBoard);
