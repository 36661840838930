import React, { useState } from 'react';
import { withCardApi } from '../../contexts/api';
import { CardApiProps } from '../../types/cards';

const Register = ({ api }: CardApiProps) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  return (
    <div>
      <div>
        <span>Register</span>
      </div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          api.user.createUser(email, password);
        }}
      >
        <div>
          <input type="text" onChange={(e) => setEmail(e.target.value)} placeholder="email" value={email} />
        </div>
        <div>
          <input type="password" onChange={(e) => setPassword(e.target.value)} placeholder="password" value={password} />
        </div>
        <div>
          <input type="submit" value="Register" />
        </div>
      </form>
    </div>
  );
};

export default withCardApi(Register);
