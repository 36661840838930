import React from 'react';
import { NavLink } from 'react-router-dom';
import { withFirebase } from '../../../contexts/firebase';
import { withCardApi } from '../../../contexts/api';
import { FirebaseProps, CardApiProps } from '../../../types/cards';
import IconButton from '../../iconButton';
import JoinIcon from '../../icons/join';
import { selectors } from '../../../contexts/redux/lobby';
import { connect } from 'react-redux';
import { getActiveUserCount } from '../../../utils';

type ConnectProps = {
  lobby: Lobby;
};

type Props = FirebaseProps & CardApiProps & ConnectProps & LobbyProps;

type LobbyProps = {
  lobbyId: string;
};

const lobbyRow = ({ lobbyId, lobby, firebase, api }: Props) => (
  <tr>
    <td>{lobby.name}</td>
    <td>{new Date(lobby.createdDate).toLocaleString()}</td>
    <td>
      <span>{getActiveUserCount(lobby)}</span>
      <span>/</span>
      <span>{lobby.maxPlayerCount}</span>
    </td>
    <td>{lobby.gameType}</td>
    <td>
      {firebase.currentUser?.uid !== lobby.host && !lobby.users[firebase.currentUser?.uid as string] && (
        <IconButton label="Join" icon={<JoinIcon />} onClick={() => api.lobby.join(lobbyId, firebase.currentUser?.uid)} />
      )}
      {lobby.users[firebase.currentUser?.uid as string] && <NavLink to={`/lobby/${lobby.lobbyId}`}>Go to lobby</NavLink>}
    </td>
  </tr>
);

const mapStateToProps = (state: any, props: LobbyProps): ConnectProps => ({
  lobby: selectors.getLobbies(state)?.[props.lobbyId],
});

export default connect(mapStateToProps)(withFirebase(withCardApi(lobbyRow)));
