import React, { useState } from 'react';
import { withCardApi } from '../../../contexts/api';
import { withFirebase } from '../../../contexts/firebase';
import { CardApiProps, FirebaseProps } from '../../../types/cards';
import { Games } from '@card-table/games';

type Props = CardApiProps & FirebaseProps;

const NewLobby = (props: Props) => {
  const [lobbyName, setName] = useState('New lobby');

  return (
    <div>
      <h1>Create new lobby</h1>
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          props.api.lobby.createLobby(props.firebase.currentUser?.uid, lobbyName, Games.GameType.BRIDGE);
        }}
      >
        <div>
          <label>
            Lobby name:
            <input type="text" value={lobbyName} onChange={(e) => setName(e.target.value)} />
          </label>
        </div>
        <div>
          <input type="submit" value="Create game" />
        </div>
      </form>
    </div>
  );
};

export default withCardApi(withFirebase(NewLobby));
