import React from 'react';
import styles from './biddingCard.module.scss';
import { Bridge, CardGames, Games } from '@card-table/games';

type BiddingCardProps = {
  currentBid: undefined | Bridge.CurrentBid;
  setBid: (bid: Games.GameEvent['payload']) => void;
  passBid: () => void;
  doubleBid: () => void;
  redoubleBid: () => void;
  isCurrentPlayer: boolean;
};

const biddingOrder: Bridge.BidSuit[] = [CardGames.Suit.CLUBS, CardGames.Suit.DIAMONDS, CardGames.Suit.HEARTS, CardGames.Suit.SPADES, 'NT'];
const levels: Bridge.BidLevel[] = [1, 2, 3, 4, 5, 6, 7];

const isHigherThanCurrentBid = (currentBid: Bridge.CurrentBid | undefined) => (level: Bridge.BidLevel, suit: Bridge.BidSuit) => {
  if (!currentBid || !currentBid.level || !currentBid.suit) {
    return true;
  }
  if (level > currentBid.level) {
    return true;
  } else if (level < currentBid.level) {
    return false;
  }
  const currentBidIndex = biddingOrder.indexOf(currentBid.suit);
  const newBidIndex = biddingOrder.indexOf(suit);
  return newBidIndex > currentBidIndex;
};

export default ({ currentBid, setBid, passBid, isCurrentPlayer, doubleBid, redoubleBid }: BiddingCardProps) => {
  const higher = isHigherThanCurrentBid(currentBid);
  return (
    <div className={styles.biddingCard}>
      <div className={styles.biddingCardTitle}>
        <h1>Bidding</h1>
      </div>
      <div className={styles.biddingCardSelectorWrapper}>
        {biddingOrder.map((suit) => (
          <div key={`bidCardWrapper_${suit}`} className={styles.biddingCardSuitWrapper}>
            {levels.map((level: Bridge.CurrentBid['level']) => {
              if (!level) {
                return null;
              }
              return (
                <div key={`bidCard_${level}_${suit}`}>
                  <button disabled={!isCurrentPlayer ? true : !higher(level, suit)} onClick={() => setBid({ level, suit })}>
                    {level} {suit === 'NT' ? suit : suit.toString().substr(0, 1)}
                  </button>
                </div>
              );
            })}
          </div>
        ))}
        <div>
          <div>
            <button disabled={!isCurrentPlayer} onClick={passBid}>
              Pass
            </button>
          </div>
          <div>
            <button disabled={!isCurrentPlayer || !currentBid || currentBid.double} onClick={doubleBid}>
              Double
            </button>
          </div>
          <div>
            <button disabled={!isCurrentPlayer || !currentBid || !currentBid.double || currentBid.redouble} onClick={redoubleBid}>
              Redouble
            </button>
          </div>
          <div>
            <div>
              <span>Current bid</span>
            </div>
            <div>
              <pre>{JSON.stringify(currentBid, null, 2)}</pre>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
