import React from 'react';
import { Bridge } from '@card-table/games';

type Props = {
  lobby: Lobby;
  updateSettings: (settings: Bridge.GameInitSettings) => void;
  settings: Bridge.GameInitSettings;
};

export default ({ lobby, updateSettings, settings }: Props) => (
  <div>
    <div>
      <div>
        <span>Set dealer</span>
      </div>
      <div>
        <select
          onChange={(e) =>
            updateSettings({
              ...settings,
              dealer: e.target.value as Bridge.PossiblePosition,
            })
          }
          value={settings.dealer}
        >
          <option value={Bridge.PossiblePosition.NORTH}>North</option>
          <option value={Bridge.PossiblePosition.EAST}>East</option>
          <option value={Bridge.PossiblePosition.SOUTH}>South</option>
          <option value={Bridge.PossiblePosition.WEST}>West</option>
        </select>
      </div>
    </div>
    <div>
      <div>
        <span>Set positions</span>
      </div>
      <div>
        {Object.values(Bridge.PossiblePosition).map((pos, index) => (
          <div key={`posSelector_${pos}`}>
            <div>
              <span>User for position {pos}</span>
            </div>
            <div>
              <select
                onChange={(e) =>
                  updateSettings({
                    ...settings,
                    positions: {
                      ...settings.positions,
                      [pos]: e.target.value as Bridge.PossiblePosition,
                    },
                  })
                }
                value={settings?.positions[pos as Bridge.PossiblePosition] || Object.keys(lobby.users)?.[index]}
              >
                {Object.keys(lobby.users).map((uid) => (
                  <option value={uid} key={`selector_${pos}_${uid}`}>
                    {uid}
                  </option>
                ))}
              </select>
            </div>
          </div>
        ))}
      </div>
    </div>
  </div>
);
