import React from 'react';
import Navbar from './navbar';
import { Switch, Route } from 'react-router-dom';
import Lobby from './lobby';
import Club from './club';
import Game from './game';
import Preview from './preview';
import styles from './postauth.module.scss';
import { withFirebase } from '../contexts/firebase';

const PostAuth = () => (
  <div className={styles.wrapper}>
    <div className={styles.navWrapper}>
      <Navbar />
    </div>
    <div className={styles.contentWrapper}>
      <Switch>
        <Route path="/lobby" component={Lobby} />
        <Route path="/club" component={Club} />
        <Route path="/game" component={Game} />
      </Switch>
    </div>
    <div className={styles.lobbyPreview}>
      <Switch>
        <Route path="/lobby/*"></Route>
        <Route path="/*">
          <Preview />
        </Route>
      </Switch>
    </div>
  </div>
);

export default withFirebase(PostAuth);
