import React from 'react';
import { Switch, Route } from 'react-router-dom';
import Register from './register';
import ForgotPassword from './forgotPassword';
import ResetPassword from './resetPassword';
import Home from './home';
import Login from './login';
import Navbar from './navbar';
import styles from './preauth.module.scss';

export default () => (
  <div className={styles.preauth}>
    <div className={styles.navbarWrapper}>
      <Navbar />
    </div>
    <div className={styles.pageWrapper}>
      <Switch>
        <Route path="/register">
          <Register />
        </Route>
        <Route path="/forgotPassword">
          <ForgotPassword />
        </Route>
        <Route path="/resetPassword">
          <ResetPassword />
        </Route>
        <Route path="/login">
          <Login />
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </Switch>
    </div>
  </div>
);
