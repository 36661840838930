import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withCardApi } from '../../../contexts/api';
import { selectors } from '../../../contexts/redux/lobby';
import { CardApiProps } from '../../../types/cards';
import { LobbyTable, LobbyTableRow } from '../../../components/lobbyList';

type LobbyProps = {
  order: string[];
  error: string;
  loading: boolean;
  sort: Sort;
  cursor: string;
};

type LobbyListType = CardApiProps & LobbyProps;

const LobbyList = (props: LobbyListType) => {
  const { api, error, loading, order, sort, cursor } = props;
  useEffect(() => {
    api.lobby.getLobbies(sort);
    return () => api.lobby.clearLobbies();
  }, [api, sort, cursor]);

  return (
    <div>
      <div>
        <span>Lobby list</span>
      </div>
      <div>
        <span>error: {error}</span>
      </div>
      <div>
        <span>loading: {loading ? 'true' : 'false'}</span>
      </div>
      <LobbyTable>
        {order?.map((id: string) => (
          <LobbyTableRow key={`lobby.${id}`} lobbyId={id} />
        ))}
      </LobbyTable>
    </div>
  );
};

const mapStateToProps = (state: any): LobbyProps => ({
  loading: selectors.getLoadingState(state),
  error: selectors.getError(state),
  order: selectors.getLobbyOrder(state),
  sort: selectors.getSort(state),
  cursor: selectors.getCursor(state),
});

export default connect(mapStateToProps)(withCardApi(LobbyList));
