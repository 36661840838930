import React from 'react';
import style from './card.module.scss';
import { CardGames } from '@card-table/games';

type CardProps = {
  card: CardGames.StandardCard;
  onClick?: () => void;
  disabled?: boolean;
};

export default ({ card, onClick, disabled }: CardProps) => {
  return (
    <div className={style.card}>
      {onClick && (
        <button onClick={onClick} disabled={disabled}>
          <span>
            {card.rank === 'unkown' && 'x'}
            {card.rank !== 'unkown' && `${card.rank}${card.suit.toString().charAt(0)}`}
          </span>
        </button>
      )}
      {!onClick && (
        <span>
          {card.rank === 'unkown' && 'x'}
          {card.rank !== 'unkown' && `${card.rank}${card.suit.toString().charAt(0)}`}
        </span>
      )}
    </div>
  );
};
