import React from 'react';
import style from './authWrapper.module.scss';

type Props = {
  children: React.ReactChild;
};

export default ({ children }: Props) => (
  <div className={style.authWrapper}>
    <div className={style.authBox}>{children}</div>
  </div>
);
