import { createSelector, createSlice, PayloadAction, Selector } from '@reduxjs/toolkit';
import { Games } from '@card-table/games';

type State = {
  game?: Game;
  currentGameState?: Games.GameState;
  // hand: Hand;
  // board: GameBoard;
};

const initialState: State = {
  game: undefined,
  currentGameState: undefined,
};

const selfSelector: Selector<any, State> = (state: any): State => state?.[slice.name] as State;

export const selectors = {
  getGame: createSelector<any, State, Game | undefined>(selfSelector, (state) => state.game),
  getCurrentGameState: createSelector<any, State, Games.GameState | undefined>(selfSelector, (state) => state.currentGameState),
};

const slice = createSlice({
  name: 'game',
  initialState,
  reducers: {
    updateGame: (state: State, action: PayloadAction<Game>) => {
      state.game = action.payload;
    },
    updateCurrentGameState: (state: State, action: PayloadAction<Games.GameState>) => {
      state.currentGameState = action.payload;
    },
  },
});

export const { updateGame, updateCurrentGameState } = slice.actions;

export default slice.reducer;
