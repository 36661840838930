import React, { useState } from 'react';
import { withFirebase } from '../../contexts/firebase';
import { FirebaseProps } from '../../types/cards';
import AuthWrapper from '../authWrapper';
import styles from './login.module.scss';

const Login = ({ firebase }: FirebaseProps) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  return (
    <AuthWrapper>
      <div className={styles.wrapper}>
        <div className={styles.title}>
          <h2>Log in</h2>
        </div>
        <form
          className={styles.form}
          onSubmit={(e) => {
            e.preventDefault();
            firebase.signIn(email, password);
          }}
        >
          <div className={styles.email}>
            <input type="text" className={styles.emailInput} onChange={(e) => setEmail(e.target.value)} placeholder="email" value={email} />
          </div>
          <div className={styles.password}>
            <input
              type="password"
              className={styles.passwordInput}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="password"
              value={password}
            />
          </div>
          <div className={styles.button}>
            <input type="submit" value="Log in" />
          </div>
        </form>
      </div>
    </AuthWrapper>
  );
};

export default withFirebase(Login);
