import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { withCardApi } from '../../../contexts/api';
import { withFirebase } from '../../../contexts/firebase';
import { CardApiProps, FirebaseProps } from '../../../types/cards';
import { connect } from 'react-redux';
import { getActiveUserCount } from '../../../utils';
import BridgeComp from './impl/bridge';
import { selectors as lobbySelectors } from '../../../contexts/redux/lobby';
import { Bridge, Games } from '@card-table/games';

type Props = {
  lobby: Lobby | undefined;
};

const GAMES = {
  [Games.GameType.BRIDGE]: BridgeComp,
};

const Lobby = ({ lobby, api, firebase }: Props & CardApiProps & FirebaseProps) => {
  const [gameSettings, setGameSettings] = useState({
    dealer: (lobby?.gameSpecificSettings as Bridge.GameInitSettings)?.dealer || Bridge.PossiblePosition.NORTH,
    positions: Object.values(Bridge.PossiblePosition).reduce((prev, curr, index) => {
      if (!lobby?.users) {
        return prev;
      }
      prev[curr as Bridge.PossiblePosition] = Object.keys(lobby.users)?.[index];
      return prev;
    }, {} as Bridge.PlayerByPosition),
  } as Bridge.GameInitSettings);

  // if (!Object.keys(gameSettings.positions).length) {
  //   setGameSettings({
  //     ...gameSettings,
  //     positions: Object.values(Bridge.PossiblePosition).reduce((prev, curr, index) => {
  //       if (!lobby?.users) {
  //         return prev;
  //       }
  //       prev[curr as Bridge.PossiblePosition] = Object.keys(lobby.users)?.[index];
  //       return prev;
  //     }, {} as Bridge.PlayerByPosition),
  //   });
  //   console.log(gameSettings);
  // }

  if (!lobby) {
    return null;
  }

  const GameSettings = GAMES[lobby.gameType as Games.GameType];

  if (!GameSettings) {
    return null;
  }

  const activeCount = getActiveUserCount(lobby);

  let canStart = false;
  if (firebase.currentUser?.uid === lobby.host && activeCount === lobby.maxPlayerCount && gameSettings) {
    canStart = true;
  }
  console.log(gameSettings);

  return (
    <div>
      <div>
        <span>{lobby.name}</span>
      </div>
      <div>
        {activeCount} / {lobby.maxPlayerCount}
      </div>
      <GameSettings lobby={lobby} updateSettings={setGameSettings} settings={gameSettings} />
      {firebase.currentUser?.uid === lobby.host && (
        <div>
          <button disabled={!canStart} onClick={() => api.game.startGame(lobby.lobbyId, gameSettings)}>
            Start game
          </button>
        </div>
      )}
    </div>
  );
};

interface MatchParams {
  lobbyId: string;
}
interface MapStateToPropsProps extends RouteComponentProps<MatchParams> {}

const mapStateToProps = (state: any, props: MapStateToPropsProps): Props => ({
  lobby: lobbySelectors.getCurrentLobby(state),
});

export default connect(mapStateToProps)(withCardApi(withFirebase(Lobby)));
