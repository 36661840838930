import React from 'react';
import { selectors } from '../../contexts/redux/game';
import BridgeBoard from './board/bridge/bridgeBoard';
import { connect } from 'react-redux';
import { withFirebase } from '../../contexts/firebase';
import { FirebaseProps } from '../../types/cards';
import styles from './game.module.scss';
import { Bridge, Games } from '@card-table/games';

type GameProps = {
  gameState?: Games.GameState;
  gameId?: string | unknown;
};

const GameBoard = ({ gameState, firebase, gameId }: GameProps & FirebaseProps) => {
  if (!firebase.currentUser?.uid || !gameId) {
    return null;
  }
  return (
    <div className={styles.game}>
      <div className={styles.board}>
        {gameState && <BridgeBoard gameId={gameId} gameState={gameState as Bridge.GameState} userId={firebase.currentUser.uid} />}
      </div>
      <div className={styles.extra}>
        <span>Extra info</span>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any): GameProps => {
  return {
    gameState: selectors.getCurrentGameState(state),
    gameId: selectors.getGame(state)?.gameId,
  };
};

export default connect(mapStateToProps)(withFirebase(GameBoard));
