import Firebase from '../../firebase';
import { Loader } from './loader';

export default class ResourceLoader {
  loader: Loader;
  firebase: Firebase;

  constructor(loader: Loader, firebase: Firebase) {
    this.loader = loader;
    this.firebase = firebase;
  }
}
