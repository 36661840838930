import React from 'react';
import { Switch, Route, RouteComponentProps } from 'react-router-dom';

import LobbyList from './list';
import SingleLobby from './single';
import NewLobby from './new';

/*
  We will use this page to:
  - show all public lobbies
  - show all "club level" lobbies
  - let the user create a lobby
  - show pending invites for lobbies?
  - join a private lobby based on code?

  - thoughts??:
    - should a private lobby still show, just not be joinable without password?
*/

interface MatchParams {}
interface Props extends RouteComponentProps<MatchParams> {}

export default ({ location }: Props) => (
  <Switch location={location}>
    <Route location={location} path="/lobby/create" component={NewLobby} />
    <Route location={location} exact path="/lobby/:lobbyId" component={SingleLobby} />
    <Route location={location} path="/lobby" component={LobbyList} />
  </Switch>
);
