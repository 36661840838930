import { createSelector, createSlice, PayloadAction, Selector } from '@reduxjs/toolkit';

type State = {
  user?: User;
};

const initialState: State = {
  user: undefined,
};

const selfSelector: Selector<any, State> = (state: any): State => state?.[slice.name] as State;

export const selectors = {
  getUser: createSelector<any, State, User | undefined>(selfSelector, (state) => state.user),
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateUser: (state, action: PayloadAction<User>) => {
      state.user = action.payload;
    },
  },
});

export const { updateUser } = slice.actions;

export default slice.reducer;
