import ResourceLoader from '../resourceLoader';
import { updateUser } from '../../../redux/user';
import { dispatch } from '../../../redux/index';

export default class UserApi extends ResourceLoader {
  online: ConnectionStatus = {
    state: 'ONLINE',
    lastUpdated: this.firebase.databaseHelper.ServerValue.TIMESTAMP,
  };

  offline: ConnectionStatus = {
    state: 'OFFLINE',
    lastUpdated: this.firebase.databaseHelper.ServerValue.TIMESTAMP,
  };

  createUser = async (email: string, password: string) => {
    await this.firebase.createUser(email, password);
    this.firebase.signIn(email, password);
  };

  updateUser = async (user: User) => {
    dispatch(updateUser(user));
  };

  connect = async (userId: string) => {
    const statusRef = this.firebase.database.ref(`/userStatus/${userId}`);
    this.firebase.database.ref('.info/connected').on('value', (snapshot) => {
      // If we're not currently connected, don't do anything.
      if (!snapshot.val()) {
        return;
      }
      statusRef
        .onDisconnect()
        .set(this.offline)
        .then(() => {
          // The promise returned from .onDisconnect().set() will
          // resolve as soon as the server acknowledges the onDisconnect()
          // request, NOT once we've actually disconnected:
          // https://firebase.google.com/docs/reference/js/firebase.database.OnDisconnect

          // We can now safely set ourselves as 'online' knowing that the
          // server will mark us as offline once we lose connection.
          statusRef.set(this.online);
        });
    });
    statusRef.set(this.online);
  };

  disconnect = async (userId: string) => {
    const statusRef = this.firebase.database.ref(`/userStatus/${userId}`);
    statusRef.set(this.offline);
  };

  get = async (userId: string) => {
    const user = await this.firebase.firestore.collection('user').doc(userId).get();
    return user;
  };
}
